@media (min-width: 1200px){
    .navbar-vertical.navbar-expand-xl .navbar-vertical-content,
    .navbar-vertical.navbar-expand-xl .navbar-collapse {
        width: 15rem !important;
    }
/* width: 100% для вложенных? */
}
.navbar-vertical.navbar-expand-xl {
    max-width: 15rem !important;
}

.navbar-vertical .navbar-nav .nav-item {
    border-radius: 8px;
    padding: 10px 18px;
}

.navbar-vertical .nav-item .nav-item {
    padding-left: 0;
    padding-right: 0;
}

.navbar-vertical .navbar-nav .nav-item:hover {
    background: #D6F5F380;
}
.navbar-glass {
    background-color: transparent !important;
}

.navbar-vertical .toggle-icon-wrapper {
    margin: 0 !important;
}
.navbar-toggler-humburger-icon:hover, .navbar-toggler-humburger-icon:focus {
    background: none !important;
}
.navbar-toggle-icon:before, .navbar-toggle-icon:after {
    display: none !important;
}
.navbar-toggle-icon.collapsed img{
    rotate: 180deg;
}
