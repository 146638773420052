.menuElementLi {
    /*overflow: hidden;*/
    text-align: center;
    border-bottom: none;
    padding-top: 5px;
    cursor: pointer;
}

.menuElementA {
    vertical-align: middle;
    color: white;
    text-align: center;
    width: 70px;
    line-height: 62px;
    transition: all 0.3s;
    display: inline-block;
    height: 62px;
    /*position: relative; почему бля я хз */
}

.menuElementA:hover {
    /*scale: 1.1;*/
    background-color: #444444;
    border-radius: 2px;
    transition: all .3s ease-in-out;
}

.menuElementA:hover svg *[stroke="#466A68"],
.linkActive svg *[stroke="#466A68"]{
    stroke:#49C2BB
}
.linkActive{
    background-color: #444444;
}

.popupTitle{
    display: inline;
    opacity: 0;
    position: absolute;
    right: -9990px;
    transform: translateX(calc(100% + 14px));
    line-height: normal;
    margin-top: 14px;
    color: black;
    background: white;
    padding: 6px 10px;
    border-radius: 8px;
    transition: opacity .5s ease-in-out, scale .5s ease-in-out;

}

.menuUl:hover .popupTitle{
    right: 0;
    opacity: 1;
}
.menuElementA:hover .popupTitle{
    scale: 1.1

}
