.search-wrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px 30px;
    border-radius: 8px;
    background: #FFF;
    position: relative;
}

:root{
    --burger-dist: -50px;
}

.burger-mob{
    visibility: hidden;
    display: none;
    cursor: pointer;
    position: absolute;
    right:  var(--burger-dist);
    top: 0;
    bottom: 0;
    width: clamp(1.125rem + 20px, 0.279rem + 3.162vw + 20px, 3.125rem + 20px);
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 8px;
    user-select: none;
}
.smart-search{
    max-width: 562px;
    width: calc( 100vw - 414px );
    /*min-width: 235px;*/
    height: clamp(1.125rem, 0.279rem + 3.162vw, 3.125rem);
    border-radius: 30px;
    background: #F7F6F4 url("http://local.overhang.io:8000/static/smart-design/images/smartCourse/lupa.svg") no-repeat ;
    background-position: 15px center;
    font-size: 1rem;
    padding: 10px 12px 10px 45px;
    border: none;
    outline: none;
}

@media (max-width: 540px ) {
    .search-wrapper {
        width: calc(100% + var(--burger-dist));
        align-self: flex-start;
    }
    .burger-mob {
        visibility: visible;
        display: flex;
    }
    .search-wrapper {
        padding: 10px 16px;
    }
}
@media (max-width: 767px){
    .smart-search {
        /*padding-right: 42px;*/
        background-color: unset;
    }

}
.search-size {
  height: clamp(1.125rem, 0.279rem + 3.162vw, 3.125rem);
  width: clamp(1.125rem, 0.279rem + 3.162vw, 3.125rem);
  border-radius: 50%;
}
.skeleton-circle {
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
  background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
  background-size: 800px 100px;
  animation: skel-waves 2s infinite ease-out;
}


@keyframes skel-waves {
  0% {
    background-position: -268px 0;
  }
  100% {
    background-position: 268px 0;
  }
}

/* гасим стрелку в дропдаун меню тогле*/
.search-wrapper  .dropstart  .dropdown-toggle::before {
  display: none;
}
.dropdown-toggle {
  cursor: pointer;
}
