.headerRightPanel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: clamp(0.938rem, -0.134rem + 2.232vw, 1.875rem);
    /*width: 156px;*/
    /*height: 50px;*/
}

.userpic{
    width: clamp(1.125rem, 0.279rem + 3.162vw, 3.125rem);
    height: clamp(1.125rem, 0.279rem + 3.162vw, 3.125rem);
    border-radius: 50%;
}
.ua-icon img{
    width: clamp(0.813rem, 0.027rem + 1.637vw, 1.5rem);
}

.ua-icon {
  cursor: pointer;
}
