:root {
    --menu-bar-width: 70px;
}

.menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: var(--menu-bar-width);
    z-index: 10;
    flex-shrink: 0;
    /*min-height: 100%;*/
    background-color: #242424;
    box-shadow: 10px 0 20px RGBA(233, 133, 110, 0.1);

    transition: left .4s ease;
}

.menuUl {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    height: 100%;
}

.showbar {
    left: 0;
}

.invert {
    filter: invert(100%);
}

.spacer {
    flex-grow: 1;
}

.blind-menu {
    display: none;
}

@media (max-width: 540px) {
    /*мобила*/
    .menu {
        /* в мобильном виде прячем */
        left: -70px;
    }

    .showbar {
        left: 0;
    }

    /*todo dodo*/
    .content-wrapper.main-container {
        margin-left: 0;
    }
}

.submenuUl {
    position: absolute;
    left: var(--menu-bar-width);
    top: 0;
    bottom: 0;
    margin: 0;
    width: 240px;
    background: white;
    padding: 30px 20px;
    list-style: none;
    color: #242424;;
}

.submenuUl h3 {
    font-weight: 500;
    font-size: 20px;
}

.submenuUl a {
    text-decoration: none;
    color: #242424;
    font-weight: 400;
    font-size: 14px;

}

.submenuUl a:hover {
    color: var(--all-color-green, #49C2BB);
}

