.smart-header {
  flex: 1
}
.main-header{
    /*  same as wrapper  */
    display: flex;
    /*margin: 0 clamp(0.75rem, -1.25rem + 4.167vw, 2.5rem);*/
    flex-direction: column;
    /*  центрирование не нужно тк контент на всю ширину этого контейнера'  */
    /*     justify-content: center; */
    /*  центрирование нужно для большой ширины  */
    align-items: center;
}
.header-content{
    width: 100%;
    padding: 20px 0;
    max-width: 1200px;
}




/*.header {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    height: 80px;*/
/*    border-radius: 8px;*/
/*    padding: 15px 30px 15px 30px;*/
/*    background-color: #fff;*/
/*    box-shadow: 2px 4px rgba(176, 176, 176, 0.15);*/
/*    margin: 60px 82px;*/
/*    margin-left: 70px;*/
/*}*/

/*@media (max-width: 540px) {*/
/*    .header {*/
/*        margin-left: 0;*/
/*    }*/
/*}*/
