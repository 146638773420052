:root {
    --falcon-body-bg: #FAFAFA !important;
    --falcon-bg-navbar-glass: #FAFAFA !important;
    --falcon-navbar-vertical-default-link-active-color: #49C2BB !important;
}
.main-container{
    margin-left: 70px;
}

@media (max-width: 540px) {
    .main-container{
        margin-left: 0;
    }
}

.setting-toggle{
    display: none !important;
}
/** {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    box-sizing: border-box;*/
/*}*/
/*.leftbar {*/
/*    background: #242424;*/
/*    width: 70px;*/
/*    top: 0;*/
/*    left: 0;*/
/*    bottom: 0;*/
/*    z-index: 10002;*/
/*    position: fixed;*/
/*    transition: left .4s ease;*/
/*    !* далее для наглядности *!*/
/*    color: white;*/
/*}*/
/*@media (max-width: 540px) {*/
/*    .leftbar{*/
/*        left: -70px;*/
/*    }*/
/*}*/
/*.showbar {*/
/*    left: 0;*/
/*}*/
/*.invert{*/
/*    filter: invert(100%);*/
/*}*/
/*header {*/
/*    border-radius: 0 0 24px 24px;*/
/*    background: #D6F5F3;*/
/*}*/
/*.main-header{*/
/*    !*  same as wrapper  *!*/
/*    display: flex;*/
/*    margin: 0 clamp(0.75rem, -1.25rem + 4.167vw, 2.5rem);*/
/*    flex-direction: column;*/
/*    !*  центрирование не нужно тк контент на всю ширину этого контейнера'  *!*/
/*    !*     justify-content: center; *!*/
/*    !*  центрирование нужно для большой ширины  *!*/
/*    align-items: center;*/
/*    !* далее для наглядности *!*/
/*    background-color: yellow;*/
/*}*/
/*.header-content{*/
/*    width: 100%;*/
/*    padding: 20px 0;*/
/*    max-width: 1200px; !* 1200px в реале *!*/
/*    !* далее для наглядности *!*/
/*    max-width: 600px; !* 600px для визуала *!*/
/*    background-color: pink;*/
/*}*/
/*:root{*/
/*    --burger-dist: -50px;*/
/*}*/
/*.search-wrapper{*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    width: 100%;*/
/*    padding: 15px 30px;*/
/*    border-radius: 8px;*/
/*    background: #FFF;*/
/*    position: relative;*/
/*}*/
/*.burger-mob{*/
/*    visibility: hidden;*/
/*    display: none;*/
/*    cursor: pointer;*/
/*    position: absolute;*/
/*    right:  var(--burger-dist);*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    width: clamp(1.125rem + 20px, 0.279rem + 3.162vw + 20px, 3.125rem + 20px);*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    background: white;*/
/*    border-radius: 8px;*/
/*    user-select: none;*/
/*}*/
/*@media (max-width: 540px ) {*/
/*    .search-wrapper {*/
/*        width: calc(100% + var(--burger-dist));*/
/*        align-self: flex-start;*/
/*    }*/
/*    .burger-mob {*/
/*        visibility: visible;*/
/*        display: flex;*/
/*    }*/
/*}*/

/*.wrapper{*/
/*    display: flex;*/
/*    margin: 0 clamp(0.75rem, -1.25rem + 4.167vw, 2.5rem);*/
/*    flex-direction: column;*/
/*    !*     justify-content: center; *!*/
/*    !*     align-items: center; *!*/
/*    !* далее для наглядности *!*/
/*    background-color: lightgreen;*/
/*}*/


/*main.content {*/
/*    width: 100%;*/
/*    margin: auto;*/
/*    max-width: 1200px; !* 1200px в реале *!*/
/*    !* далее для наглядности *!*/
/*    max-width: 600px; !* 600px для визуала *!*/
/*    min-height: 500px;*/
/*    background-color: NavajoWhite;*/

/*}*/
